
$( document ).ready(function(e) {
 
    window.onscroll = function() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            $("#top").removeClass("d-none");
        } 
        else{
            $("#top").addClass("d-none");
        }
    };
    
    $("#top").click(function(event){
        event.preventDefault();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });
    
    /* tooltip*/
    $('[data-toggle="tooltip"]').tooltip();
    
    
    /* magnific */
    $('.magnific-video').magnificPopup({ type: 'video' });        
    $('.magnific-image').magnificPopup({ type: 'image' });        
    
        
    $('.easy-ajax-form').on('submit', function (event) {
								
        event.preventDefault();
        var form = $(this);
    
        $.ajax({
            url : form.data('action'),
            data: form.serialize(),
            type: "post",
            dataType: "html",
            success: function (data) {
                form.html(data);
            },
            error: function (xhr, status) {
                form.find('#error_message').removeClass('d-none');
            }
        });
		    
    });  
    
    
    $('.open-login').on( 'click', function(){
        
        
		$('#vbw_event_register').modal('toggle');
		
		
		$('html, body').animate({
	        scrollTop: $("#tab_user").offset().top
	    }, 1000);
	    
		$('#tab_user').trigger('click');            
    });  
    
    $('.vcard').on( 'click', function(){
        
        
        var vCard = vCardsJS();
        var data = $(this).data();
        
        vCard = _.merge(vCard, data);
        
        if( data.image ){
            vCard.photo.attachFromUrl(data.image, 'JPEG');
        }
              
        var file = new File([vCard.getFormattedString()], data.filename + ".vcf", {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(file);  
        
    });  
    
    
    $.fn.autocomp = function() {
        
        var input = $(this);
        
        var formId = input.data('formId');
        var portal = input.data('portalValue');
        var suggestUrl = input.data('suggestUrl');

        var form = $(formId);

                
	    var query = input.val();
	  
	    input.autocomplete({
	        appendMethod:'replace',
	        autoselect: false,
	        source:[
	            function( q, add ){
	                   
	                   
	                $.getJSON( suggestUrl + "?q="+encodeURIComponent(q),function(data){
	                    	                    
	                    var values = [];
                        var suggestions = data.suggest[portal+"Suggester"][q].suggestions;
                                                
	                    $.each(suggestions, function(index, suggestion){
	                        values.push(suggestion.term);
	                    } );
	                    
	                    add(values);
	                                 
	                });
	            }
	        ],
	     
	    }).on('selected.xdsoft',function(event){
	        form.submit();
	    });
	  
	    input.keyup(function(e){
	  
	        var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
	    
	        if(key == 13) {
	  
	            e.preventDefault();
	            input.autocomplete('destroy');
	            input.val(query);
	            
	           form.submit();
	        }
	  
	        query = input.val();
	        
	    });
        
    };
    
    $.each($('.query-text'), function(key, element){
    	$(element).autocomp();
    });
	    
});