require('../css/portal_bayme_vbm.scss');
const $ = require('jquery');
global.$ = global.jQuery = $;

const viewportPlugin = require('./plugins/viewport.js');
global.viewportPlugin = viewportPlugin;

require('./plugins/swiper.js');

const bootstrap = require('bootstrap');
global.bootstrap = bootstrap;

require('responsive-toolkit');

require('selectize');

require('block-ui');

require('jquery-unveil');
require('../lib/slideout/js/slideout.js');
require('../lib/confirm/jquery-confirm.min.js');
require('lodash');

global.moment = require('moment');
require('moment/locale/de');
global.moment.locale('de');


require('magnific-popup');

require('jquery-autocomplete');
require('jquery-fileinput');

require('flickity');

const etrackerUtil = require('./modules/etracker_util.js');
const ics = require('ics');

const flatpickrGermanLocale = require("flatpickr/dist/l10n/de.js").default.de;
global.flatpickrGermanLocale = flatpickrGermanLocale;

require("flatpickr");
const flatpickrDefaultConfig = require('./plugins/flatpickr.js');
global.flatpickrDefaultConfig = flatpickrDefaultConfig;

global.FileSaver = require('file-saver');

require("flatpickr/dist/themes/light.css");


require('./modules/forms.js');
require('./modules/backdrop.js');

require('./pages/all.js');
require('./portals/bayme_vbm.js');
require('./pages/article.js');
var htmlToImage = require('html-to-image');
var download =require('downloadjs');
var JSZip = require("jszip");
global.htmlToImage = htmlToImage;
global.download = download;
global.JSZip = JSZip;
global.ics = ics;
