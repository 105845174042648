

//Uses CommonJS, AMD or browser globals to create a jQuery plugin.

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, jQuery ) {
            if ( jQuery === undefined ) {
                // require('jQuery') returns a factory that requires window to
                // build a jQuery instance, we normalize how we use modules
                // that require this pattern but the window provided is a noop
                // if it's defined (how jquery works)
                if ( typeof window !== 'undefined' ) {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            factory(jQuery);
            return jQuery;
        };
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {
		
	var util = {
		
	}; // Object for public APIs
    
    util.prefix = function(){
    	
    	return 'aktionsrat - ';
    	
    };
   
    util.pageImpression = function(title, url){
    	
    	try {
            var etUrl = schemeAndHost + url;
            if ( eTrackerEnabled == true ){
                et_eC_Wrapper(et_aid, title, et_areas, '', etUrl, '', '', '', '', '', '', '', '', '', '');
            } else {
                console.log('eTracker disabled. title: ' + title + ', url: ' + etUrl);
            }
        } catch (e){
        }
    	
    };
    
    util.download = function(path, tag){
    	
    	try {
    		
    			
    			if ( path.indexOf('/') == 0 ) {
    				path = path.substring(1);
    			}
    			ET_Event.download(etrackerPrefix() + path, ''+tag);
    		
    	} catch (e){
    		console.log(e);
    	}
    	
    };
    
    util.init = function(){
    	
    		/*
         * initializes functionality when document is ready
         */
        $(document).ready(function() {

        });
    	
    };
    
    util.init();
    
    return util;
    
}));



