/* Article js */


$( document ).ready(function(e) {
	if( $('.device-sm').is(':visible') || $('.device-xs').is(':visible') ) moveContent("sm");
});

$(document).on('viewportChange', function(e){
	moveContent(e.new, e.old);	
});

var moveContent = function( newVP, oldVP ){
    
    var smContainer = $("[data-content-sm]");
    var mdContainer = $("[data-content-md]");
    
    if( newVP == 'sm' ){
         
        mdContainer.each(
            function( index, ele ){
                var content = $(ele).find('section');
                $("[data-content-sm][data-content-"+$(ele).data("target")+"]").append(content);
            }
        );
    }   
     
       
     if( newVP == 'md' || ( oldVP == 'sm' && newVP != 'xs' ) ){
        
        smContainer.each(
            function( index, ele ){
                var content = $(ele).find('section');
                $("[data-content-md][data-content-"+$(ele).data("target")+"]").append(content);
            }
        );     
     }         
} 
