(function () {

    function isBlank(value){      
        return !value || !value.toString().trim() || /^[\s\b\0]+$/.test(value.toString());
    }
    
    function handleSelector(e){
        
        e.preventDefault();
        
        var parent = $(this).parent('.selector')
        
        let id = $(this).attr("data-id");
        let selectorId = "#selector-" + id;
        
        $( selectorId + " .selector-selected").text($(this).text());
        $( selectorId + " .selector-option").removeClass('selector-option-selected');
        $(this).addClass('selector-option-selected');
        
        var type = $( selectorId).attr("data-type");
        var value = $(this).attr("data-value");
        
        if( !isBlank(value) ){
            parent.removeClass('selector-active');
        }
        
        if( type ){
            $( "#" + id).val(value);
        } else {
            $( "#" + id + "_by").val(value);
        }
        
        var formId = $( selectorId).attr("data-form");
        
        $( 'collapse-' + id ).trigger("click");
        $( selectorId + "_options" ).collapse('hide');
        $( formId ).submit();
        
    }
    
    function handleResetForm(e){
        
        e.preventDefault();
        
        var target = $(e.currentTarget).data('target');
        var $form = $(target); 
        
        if ( $form.length == 0 ){
            $form = $(this).closest('form');
        }
        
        $form.find('select:not(.no-reset)').each(function(key, el){ 
            var $select = $(el).selectize(); 
            var control = $select[0].selectize; control.setValue(' ', true);  
        }); 
        
        $form.find('input[type="text"]:not(.no-reset), input[type="hidden"]:not(.no-reset)').each( function(key, el){ 
            $(el).val('');
        });
        
        $form.find('input[type="checkbox"]:not(.no-reset), input[type="radio"]:not(.no-reset)').each( function(key, el){ 
            $(el).prop('checked', false);
        });
        
        $form.find('.date-picker:not(.no-reset), .date-picker-range:not(.no-reset)').each(function(index, el){
            $(el)[0]._flatpickr.clear();
        });
        
        $form.trigger('reset');
        
        if(!$form.hasClass("skip-submit")){
            $form.submit();
        }
        
    }
    
    function handleInputGroupText(e){
        
        var $inputGroupText = $(this);

        var forStr = $inputGroupText.data('for');

        var $for = $(forStr);
        
        if ( $for ){
            $for.focus();
        }
        
    }
    
    $( document ).ready(function() {

        $('select:not(.no-selectize)').selectize([]);
        
        $('.date-picker').flatpickr(flatpickrDefaultConfig);       
        
        var $selectors = $('.selector');
        $selectors.each(function(index, el){

            var $selector = $(el);
            
            var $container = null;
            
            var container = $selector.data('container');
            
            if ( container ){
                $container = $(container);
            }
            
            if ( $container && $container.length == 1 ){
                
                $container.on('click', '.selector .selector-option', handleSelector);
                
            } else {
                
                $selector.find('.selector-option').on('click', handleSelector);
                
            }

        });
        
        var $resetFormEls = $('.reset-form');
        $resetFormEls.each(function(index, el){

            var $resetFormEl = $(el);
            
            var $container = null;
            
            var container = $resetFormEl.data('container');
            
            if ( container ){
                $container = $(container);
            }
            
            if ( $container && $container.length == 1 ){
                
                $container.on('click', '.reset-form', handleResetForm);
                
            } else {
                
                $resetFormEl.on('click', handleResetForm);
                
            }

        });
        
        var $inputGroupTexts = $('.input-group-text');
        $inputGroupTexts.each(function(index, el){

            var $inputGroupText = $(el);
            
            var $container = null;
            
            var container = $inputGroupText.data('container');
            
            if ( container ){
                $container = $(container);
            }
            
            if ( $container && $container.length == 1 ){
                
                var forStr = $inputGroupText.data('for');

                $container.on('click', '.input-group-text[data-for="'+forStr+'"]', handleInputGroupText);
                
            } else {
                
                $inputGroupText.on('click', handleInputGroupText);
                
            }

        });
        
    });
    
}());
