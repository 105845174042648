//Uses CommonJS, AMD or browser globals to create a jQuery plugin.

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, jQuery ) {
            if ( jQuery === undefined ) {
                // require('jQuery') returns a factory that requires window to
                // build a jQuery instance, we normalize how we use modules
                // that require this pattern but the window provided is a noop
                // if it's defined (how jquery works)
                if ( typeof window !== 'undefined' ) {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            factory(jQuery);
            return jQuery;
        };
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {
		
	var config = {
		altInput: true,
	    altFormat: "d.m.Y",
	    showMonths: 2,
	    minDate: "today",
	    disableMobile: true,
	    dateFormat: 'U',
	    wrap: true,
	    locale: flatpickrGermanLocale,
	    
	    onValueUpdate: function(selectedDates, dateStr, instance){
	        
	        var input = $(instance.input);
	        
	        var smallerthan = input.data('smallerthan');
	        var biggerthan = input.data('biggerthan');
	    
	        
	        if( typeof smallerthan != 'undefined' ){
	            
	            let smallerthanInstance = $(smallerthan)[0]._flatpickr;  
	            smallerthanInstance.set('minDate', dateStr);
	        }        
	        

	        
	    }
	}; // Object for public APIs
    
    return config;
    
}));
