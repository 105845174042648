// Uses CommonJS, AMD or browser globals to create a jQuery plugin.

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery','responsive-toolkit'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, jQuery, ResponsiveBootstrapToolkit ) {
            if ( jQuery === undefined ) {
                // require('jQuery') returns a factory that requires window to
                // build a jQuery instance, we normalize how we use modules
                // that require this pattern but the window provided is a noop
                // if it's defined (how jquery works)
                if ( typeof window !== 'undefined' ) {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            factory(jQuery);
            return jQuery;
        };
    } else {
        // Browser globals
        factory(jQuery, ResponsiveBootstrapToolkit);
    }
}(function ($, ResponsiveBootstrapToolkit) {
		
	var viewportPlugin = {
		currentBreakpoint: null
	}; // Object for public APIs
	
	var visibilityDivs = {
	        'xs': $('<div class="device-xs d-block d-sm-none"></div>'),
	        'sm': $('<div class="device-sm d-none d-sm-block d-md-none"></div>'),
	        'md': $('<div class="device-md d-none d-md-block d-lg-none"></div>'),
	        'lg': $('<div class="device-lg d-none d-lg-block d-xl-none"></div>'),
	        'xl': $('<div class="device-xl d-none d-xl-block"></div>')
    };
	
	ResponsiveBootstrapToolkit.use('Custom', visibilityDivs);

    var onResize = function(e){
		var breakpoint = viewportPlugin.getBreakpoint();
        if (breakpoint != viewportPlugin.currentBreakpoint) {
    		try {
	            $.event.trigger({
	                type: 'viewportChange',
	                old: viewportPlugin.currentBreakpoint,
	                new: breakpoint
	            });
    		} catch (e){
    			console.log('exception occured when triggering viewportChange', e);
    		}
            viewportPlugin.currentBreakpoint = breakpoint;
            
        }
    };
    
    viewportPlugin.getCurrentBreakpoint = function(){
    	
		return viewportPlugin.currentBreakpoint;
    	
    };
    
    /*
     *  @returns {String} into bootstrap viewport category
     */
	viewportPlugin.getBreakpoint = function() {
        var breakpoint = 'xs';

        if (ResponsiveBootstrapToolkit.is('xs')) {
            breakpoint = 'xs';
        } else if (ResponsiveBootstrapToolkit.is('sm')) {
            breakpoint = 'sm';
        } else if (ResponsiveBootstrapToolkit.is('md')) {
            breakpoint = 'md';
        } else if (ResponsiveBootstrapToolkit.is('lg')) {
            breakpoint = 'lg';
        } else if (ResponsiveBootstrapToolkit.is('xl')) {
            breakpoint = 'xl';
        }

        return breakpoint;
    };
    
    viewportPlugin.is = function(check){
    		
    		return ResponsiveBootstrapToolkit.is(check);
    	
    };
    
    viewportPlugin.init = function(){
    	
    		/*
         * initializes functionality when document is ready
         */
        $(document).ready(function() {

    		$(window).bind('resize', onResize);
    		viewportPlugin.currentBreakpoint = viewportPlugin.getBreakpoint();
        		
        });
    	
    };
    
    viewportPlugin.init();
    
    return viewportPlugin;
    
}));