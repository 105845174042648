$( document ).ready(function() {

    backDrop = $('<div class="modal-backdrop d-none"></div>');   
    backDrop.appendTo($('header'));

    $('.toolbar-nav-tabs .nav-link').on('click', function(){
        backDrop.addClass('toolbar-modal-backdrop');
        backDrop.removeClass('d-none');
    });
    
    $('body').on('click', '.toolbar-tab-content .close, .toolbar-modal-backdrop', function(){
        $('.toolbar-nav-tabs .nav-link.active, .toolbar-tab-pane.active').removeClass('active');
        backDrop.removeClass('toolbar-modal-backdrop');
        backDrop.addClass('d-none');
    });
    
    $('.remove-backdrop').on('click', function(){
        backDrop.addClass('d-none');
    });    
    
    $('.show-backdrop').on('click', function(){
        backDrop.removeClass('d-none');
    });
        
    $('.toggle-backdrop').on('click', function(){
        backDrop.toggleClass('d-none');
    });
    
    	          
});